.rhap_main-controls-button {
    color: rgba(0, 0, 0, 0.712) !important;
}
.rhap_volume-indicator {
color: aquamarine !important;
}
@media only screen and (max-width: 800px) {
    .audioplayer{
        width: 90% !important;
    }
    .cardimage{
        margin-bottom: auto;
        margin-left: 8px;
        width: 100px !important;
    }
    .tracklist{
        width: 100% !important;
    }
};